import request from '@/utils/request'


// 查询发票-发票信息列表
export function listUser(query) {
  return request({
    url: '/invoice/user/list',
    method: 'get',
    params: query
  })
}

// 查询发票-发票信息分页
export function pageUser(query) {
  return request({
    url: '/invoice/user/page',
    method: 'get',
    params: query
  })
}

// 查询发票-发票信息详细
export function getUser(data) {
  return request({
    url: '/invoice/user/detail',
    method: 'get',
    params: data
  })
}

// 新增发票-发票信息
export function addUser(data) {
  return request({
    url: '/invoice/user/add',
    method: 'post',
    data: data
  })
}

// 修改发票-发票信息
export function updateUser(data) {
  return request({
    url: '/invoice/user/edit',
    method: 'post',
    data: data
  })
}

// 删除发票-发票信息
export function delUser(data) {
  return request({
    url: '/invoice/user/delete',
    method: 'post',
    data: data
  })
}
